































































































import { Component, Vue } from 'vue-property-decorator';
import CInterconBpForecast from '@/modules/forecast/interconBpForecast.vue';
import CITargetIndicator from '@/modules/forecast/interconTargetIndicator.vue';
import store from '@/services/store';
import {number, string} from "@amcharts/amcharts4/core";
import { Ax } from '@/utils';

import axios from "axios";

interface BaseObject {
    mainId?: number;
    id?: number;
    name_ru?: string;
    name_kz?: string;
    name_en?: string;
    programId?: number;
    directionId?: number;
    goals?: any[];
    goalId?: number;
    indicators?: any[];
    macroindicator?: boolean;
    sgpDoc?: any;
    abp?: any[];
    units?: any;
    years?: any[];
}

@Component({
    components: {
        'c-inter-bp-forecast': CInterconBpForecast,
        'ci-target-indicator': CITargetIndicator
    }
})
export default class CInterconnection extends Vue {
    private get usrId() {
        if (store.state.user.sub === undefined) { return null; }
        return store.state.user.sub;
    }

    // private headParams: any;
    // private programsObj: BaseObject[] = [];
    private serviceParams: any;
    private forecastFormArr: any;
    private programObjArr: any;
    private directArr: any;
    private goalObjArr: any;
        // private abpObj: any;
    private budgetHeadParams: any;
    private error: any;
    private isGridEmpty: boolean | undefined;

    private async downloadAllSgpDocumentsReport() {
      if ((this.serviceParams && Object.keys(this.serviceParams).length !== 0)) {
        const params = JSON.stringify({
          serviceParams: this.serviceParams,
          programObjArr: this.programObjArr
        });
        const urls = '/api-py/interconnection_target_indicator_new/'+encodeURI(params);
        Ax(
            {
              url: urls, //  Тело файла
              method: 'POST',
              data: null,
              responseType: 'blob'
            },
            (data: any) => {
              const url = window.URL.createObjectURL(new Blob([data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', 'Взаимоувязка_ЦИ_и_БП_Целевые_индикаторы.xls');// or any other extension
              document.body.appendChild(link);
              link.click();
            },
            (error) => {
              this.makeToast('danger', 'Ошибка формирования отчета', error.toString());
            }
        );
      }
    }

    private async downloadBudgetReport(){
        if (this.budgetHeadParams && Object.keys(this.budgetHeadParams).length !== 0 && this.isGridEmpty == true){
            const params = JSON.stringify(this.budgetHeadParams);
            // console.log('params: '+ JSON.stringify(params));
            const urls = '/api-py/intercon_budget_report/'+encodeURI(params);
            Ax(
                {
                    url: urls, //  Тело файла
                    method: 'POST',
                    data: null,
                    responseType: 'blob'
                },
                (data: any) => {
                    const url = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'Взаимоувязка_ЦИ_и_БП_Бюджетные_программы.xls');// or any other extension
                    document.body.appendChild(link);
                    link.click();
                },
                (error) => {
                    this.makeToast('danger', 'Ошибка формирования отчета', error.toString());
                }
            );
        }
    }

    private listBudgetDocuments(params: any, dataSize: any){
        // console.log('dataSize : ' + dataSize);
        if (dataSize == 0){
            this.isGridEmpty = false;
        } else {
            this.isGridEmpty = true;
        }
        if (params && dataSize !== 0){
            // console.log('params: ', JSON.stringify(params));
            this.budgetHeadParams = params;
        }
    }

    private listSgpDocuments(serviceParams: any, forecastFormArr: any, programObjArr: any, directArr: any, goalObjArr: any){
      this.serviceParams = serviceParams;
      this.forecastFormArr = forecastFormArr;
      this.programObjArr = programObjArr;
      this.directArr = directArr;
      this.goalObjArr = goalObjArr;
    }

    private makeToast(variant: any, title: string, tostbody: any) {
        this.$bvToast.toast(tostbody, {
            title: title,
            variant: variant,
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000,
            appendToast: true
        });
    }

    private infoCostForm(info_id: any) {
        const that = this;
        Ax(
            {
                url: '/api-py/get-info/' + info_id, //  Тело файла
                method: 'POST',
                data: null,
                responseType: 'blob'
            },
            (data: any) => {
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = url;
                let file = '';
                if (info_id == 25) {
                    file = 'Взаимоувязка целевых индикаторов и бюджетных программ';
                }
                link.setAttribute('download', file + '.pdf');// or any other extension
                document.body.appendChild(link);
                link.click();
            },
            (error) => {
                that.error = error;
            }
        );
    }
}
